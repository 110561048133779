@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.cdnfonts.com/css/butler');
@import url('https://fonts.cdnfonts.com/css/gavency-free');

body{
  padding: 0%;
  margin: 0%;
  font-family: outfit;
}
a{
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.hide_scrollbar::-webkit-scrollbar{
  display: none;

}
.hide_scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.not_found{
  transition: 0.2s all linear ease-in;
  animation: moveBackground 10s linear infinite;
}

.MuiModal-root:focus {
  outline: none;
}

.MuiModal-root .MuiBox-root:focus {
  outline: none;
}

@keyframes moveBackground {
  0% {
    background-position: 100px 0;
  }
  50%{
    background-position: 140px -20px;
  }
  100% {
    background-position: 100px 0;
  }
}

.three-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}